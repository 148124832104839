import { Card, CardBody, CardHeader, CardProps, GridItem, Heading, SimpleGrid, Stack, Text, Tooltip } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'
import { Link as ChakraLink } from '@chakra-ui/react'

import { useGetAccountQuery } from '@/redux/api/workspace'

export type WorkspaceNewProjectActionsProps = {
  workspaceSlug: string
} & CardProps

export function WorkspaceNewProjectActions({ workspaceSlug, ...cardProps }: WorkspaceNewProjectActionsProps) {
  const { data } = useGetAccountQuery({ owner: workspaceSlug })
  const { is_org: isOrg } = data || {}

  return (
    <Card variant="no-border" flexGrow={1} {...cardProps}>
      <CardHeader>
        <Heading fontWeight="semibold" fontSize="xl">
          Get the most out of Mayhem
        </Heading>
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={2} paddingY={6} spacingY={16} templateColumns="1fr 12rem">
          <Stack gap={2}>
            <Text fontWeight="semibold">Invite Your Team</Text>
            <Text textColor="gray-text">Collaborate with your team in a shared workspace</Text>
          </Stack>
          <GridItem textAlign="center">
            {isOrg ? (
              <ChakraLink variant="brand" as={WouterLink} to={`/${workspaceSlug}/-/settings?jumpTo=users&scroll=users`}>
                Add members
              </ChakraLink>
            ) : (
              <Tooltip label="You can only add members to non-personal workspaces. Create a new, shareable workspace by clicking the icon in the bottom left of your screen.">
                <Text textColor="disabled-link-text">Add members</Text>
              </Tooltip>
            )}
          </GridItem>
          <Stack gap={2}>
            <Text fontWeight="semibold">Integrate Mayhem</Text>
            <Text textColor="gray-text">Mayhem integrates with a wide variety of languages, tools, etc.</Text>
          </Stack>
          <GridItem textAlign="center">
            <ChakraLink variant="brand" as={WouterLink} to={`/${workspaceSlug}/-/integrations?scroll=languages`}>
              Integrate Mayhem
            </ChakraLink>
          </GridItem>
          <Stack gap={2}>
            <Text fontWeight="semibold">Download CLI</Text>
            <Text textColor="gray-text">Use the Mayhem CLI to create projects</Text>
          </Stack>
          <GridItem textAlign="center">
            <ChakraLink variant="brand" as={WouterLink} to="/-/installation">
              Download CLI
            </ChakraLink>
          </GridItem>
          <Stack gap={2}>
            <Text fontWeight="semibold">Read the Docs</Text>
            <Text textColor="gray-text">Access tutorials and learn more about Mayhem</Text>
          </Stack>
          <GridItem textAlign="center">
            <ChakraLink variant="brand" href="https://docs.mayhem.security" paddingX={6} isExternal>
              Read the docs
            </ChakraLink>
          </GridItem>
        </SimpleGrid>
      </CardBody>
    </Card>
  )
}
