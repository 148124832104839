import React, { useEffect, useState } from 'react'

import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { FormHelperText, Tooltip, List, Box, HStack, ListIcon, ListItem } from '@chakra-ui/react'
import BaseValidatorIcon from '@material-design-icons/svg/sharp/radio_button_unchecked.svg?react'
import CancelIcon from '@material-design-icons/svg/sharp/cancel.svg?react'
import CheckCircleIcon from '@material-design-icons/svg/sharp/check_circle.svg?react'

import { useGetUserSettingQuery } from '../../redux/api/workspace'

import { isPasswordAdequateLength, containsUnsupportedUnicode, passwordIncludesString, passwordRestrictions } from './utils'

const scores: Record<number, string> = {
  0: 'Very Weak',
  1: 'Very Weak',
  2: 'Weak',
  3: 'Weak',
  4: 'Strong'
}

const CheckerBar = ({ currentScore, barThreshold }: { currentScore: number; barThreshold: number }) => {
  const isBarActive = currentScore >= barThreshold
  let barBackgroundColor = 'subtleBg'
  if (isBarActive) {
    if (currentScore > 3) {
      barBackgroundColor = 'good'
    } else if (currentScore > 2) {
      barBackgroundColor = 'warning'
    } else {
      barBackgroundColor = 'bad'
    }
  }

  return <Box width="24%" height={4} backgroundColor={barBackgroundColor} transition="100ms background-color ease-in" />
}

const Validator = ({ isValid, text, touched }: { isValid: boolean; text: string; touched: boolean }) => {
  let icon = BaseValidatorIcon
  let iconColor = 'gray'

  if (touched) {
    if (isValid) {
      icon = CheckCircleIcon
      iconColor = 'good'
    } else {
      icon = CancelIcon
      iconColor = 'bad'
    }
  }

  return (
    <ListItem>
      {icon && <ListIcon as={icon} fill={iconColor} />}
      {text}
    </ListItem>
  )
}

type Props<Fields extends FieldValues> = {
  hideStringMatchValidator?: boolean
  passwordField: Path<Fields>
  email: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  username: string | undefined
}

export function PasswordStrengthField<Fields extends FieldValues>({
  hideStringMatchValidator,
  passwordField,
  email,
  firstName,
  lastName,
  username
}: Props<Fields>) {
  const { getFieldState, watch } = useFormContext<Fields>()

  const fields = watch()
  const password = fields[passwordField]
  const { isTouched } = getFieldState(passwordField)

  const { data: configData } = useGetUserSettingQuery()
  const { password_strength_enabled: passwordStrengthEnabled = true } = configData || {}

  const [score, setScore] = useState(0)
  const [warning, setWarning] = useState('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [lengthValidator, setLengthValidator] = useState(false)
  const [stringMatchValidator, setStringMatchValidator] = useState(false)
  const [commonPasswordValidator, setCommonPasswordValidator] = useState(false)
  const [unicodeValidator, setUnicodeValidator] = useState(false)

  useEffect((): void => {
    if (!password) {
      return
    }
    async function loadZxcvbn() {
      const zxcvbn = (await import('zxcvbn')).default

      const definedUserFields = [email, firstName, lastName, username].filter((x) => x !== undefined) as string[]
      const { score, feedback } = zxcvbn(password, [...definedUserFields, ...passwordRestrictions])
      const { warning = '', suggestions = [] } = feedback

      const isCommonPassword = warning.includes('common') && warning.includes('password')

      setScore(score)
      setWarning(warning)
      setSuggestions(suggestions)
      setLengthValidator(isPasswordAdequateLength(password, passwordStrengthEnabled))
      setStringMatchValidator(
        !passwordIncludesString(password, email) &&
          !passwordIncludesString(password, firstName) &&
          !passwordIncludesString(password, lastName) &&
          !passwordIncludesString(password, username)
      )
      setCommonPasswordValidator(!!password && !isCommonPassword)
      setUnicodeValidator(!containsUnsupportedUnicode(password))
    }
    loadZxcvbn()
  }, [isTouched, password, firstName, lastName, username, email, passwordStrengthEnabled])

  return (
    <>
      <HStack marginTop={2} data-testid="password-strength">
        <CheckerBar currentScore={score} barThreshold={1} />
        <CheckerBar currentScore={score} barThreshold={2} />
        <CheckerBar currentScore={score} barThreshold={3} />
        <CheckerBar currentScore={score} barThreshold={4} />
      </HStack>
      <FormHelperText textAlign="end">
        <Tooltip
          isDisabled={score === 4 || !password}
          label={warning || (passwordStrengthEnabled ? 'Increase password complexity to proceed' : 'Increased password complexity recommended')}
        >
          {!password ? 'No Password' : scores[score]}
        </Tooltip>
      </FormHelperText>

      <List>
        <Validator isValid={lengthValidator} text={`Minimum of ${passwordStrengthEnabled ? '11' : '6'} characters`} touched={isTouched} />
        {passwordStrengthEnabled && (
          <React.Fragment>
            {!hideStringMatchValidator && (
              <Validator isValid={stringMatchValidator} text="Does not match your email, name, or username" touched={isTouched} />
            )}
            <Validator isValid={commonPasswordValidator} text="Is not in a list of common passwords" touched={isTouched} />
            {!unicodeValidator && isTouched && (
              <Validator isValid={unicodeValidator} text="Should not contain special unicode characters" touched={isTouched} />
            )}
            {score < 4 &&
              password &&
              suggestions.map((suggestion) => <Validator key={suggestion} isValid={false} text={suggestion} touched={isTouched} />)}
          </React.Fragment>
        )}
      </List>
    </>
  )
}
