import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

import { DsbomImageRow } from './DsbomImageRow'

import ListDefaults from '@/components/ListDefaults'
import { MayhemPagination } from '@/components/MayhemPagination'
import { useQuery } from '@/hooks'
import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useGetMdsbomWorkspaceImageReposQuery } from '@/redux/api/mdsbom'
import { cleanInteger } from '@/util/numbers'

interface Props {
  workspaceSlug: string
}

export function DsbomImagesList({ workspaceSlug }: Props) {
  const query = useQuery()

  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { data: imagesData, isFetching } = useGetMdsbomWorkspaceImageReposQuery({ workspace: workspaceSlug, offset: (page - 1) * perPage, perPage })

  const { image_repos: imageRepos = [], count = 0 } = imagesData || {}

  return (
    <Card>
      <CardHeader borderBottom="0.5px solid var(--chakra-colors-chakra-border-color)">
        <Heading variant="cardHeading">Images</Heading>
      </CardHeader>
      <CardBody>
        {imageRepos?.map((imageRepo) => <DsbomImageRow key={imageRepo.image_repo_id} workspaceSlug={workspaceSlug} imageRepo={imageRepo} />)}
        <ListDefaults isLoading={isFetching} nItems={count} itemName="image" />
      </CardBody>
      <MayhemPagination isLoading={isFetching} total={count} />
    </Card>
  )
}
