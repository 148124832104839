import React from 'react'

import { Text } from '@chakra-ui/react'

import { useGetRunQuery } from '../../redux/api/runs'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  phaseName: string
}

// These fields were renamed at some point, keep legacy behavior. corpusMinimization* fields can be removed
// shortly after initial deployment, as these should only affect runs in the preparation phase during transition.
interface RunAttributes {
  corpusMinimizationNumProcessedTestcases: number | undefined
  corpusMinimizationNumTotalTestcases: number | undefined
  n_initial_coverage_collection_processed: number | undefined
  n_initial_coverage_collection_total: number | undefined
}

export function PreparationProgress({ workspaceSlug, projectSlug, targetSlug, runNumber, phaseName }: Props) {
  const { data: run } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })

  const {
    corpusMinimizationNumProcessedTestcases = 0,
    corpusMinimizationNumTotalTestcases = 0,
    n_initial_coverage_collection_processed: initialCoverageCollectionProcessed = 0,
    n_initial_coverage_collection_total: initialCoverageCollectionTotal = 0
  } = (run?.run_attributes as RunAttributes) || {}

  const processed = initialCoverageCollectionProcessed || corpusMinimizationNumProcessedTestcases
  const total = initialCoverageCollectionTotal || corpusMinimizationNumTotalTestcases

  const { preparing_at = null, started_at = null, ended_at = null } = run?.phases?.find((phase) => phase.phase_name === phaseName) || {}

  const percent = total === 0 ? 0 : ((processed / total) * 100).toFixed(2)

  if (!preparing_at || started_at || ended_at || !percent || processed > total) {
    return <React.Fragment />
  }

  return <Text>Analyzing and Optimizing Test Suite: {percent}%</Text>
}
