import { Box, Card, CardBody, CardBodyProps, CardHeader, CardProps, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'

interface Props extends CardProps {
  children: React.ReactNode
  actions?: React.ReactNode
  title?: string
  titleCentered?: boolean
  titleAction?: React.ReactNode
  headerActions?: React.ReactNode
  CardBodyProps?: CardBodyProps
}

export function TableCard({
  actions,
  children,
  title,
  titleCentered,
  headerActions,
  CardBodyProps = { paddingTop: 0 },
  titleAction,
  ...props
}: Props) {
  return (
    <Card {...props}>
      {title && (
        <CardHeader>
          <Grid width="full" templateColumns="1fr" alignItems="baseline">
            <GridItem gridRow={1} gridColumn={1} textAlign={titleCentered ? 'center' : 'start'}>
              <Heading variant="cardHeading">
                {title} {titleAction}
              </Heading>
            </GridItem>
            <GridItem gridRow={1} gridColumn={1} justifySelf="end">
              {headerActions && <Box>{headerActions}</Box>}
            </GridItem>
          </Grid>
        </CardHeader>
      )}
      {actions && <CardHeader flexShrink={0}>{actions}</CardHeader>}
      <CardBody overflowY="auto" {...CardBodyProps}>
        {children}
      </CardBody>
    </Card>
  )
}
