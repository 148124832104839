import React, { useCallback } from 'react'
import { IconButton, useToast } from '@chakra-ui/react'
import DeleteIcon from '@material-design-icons/svg/sharp/delete.svg?react'

import { getMessageFromRtkError } from '@/util/errors'

interface Props {
  onDelete?: () => void
  deleteAction: () => Promise<void>
  currentUserHasPermission: boolean
  isDeleting: boolean
  entity: 'User' | 'Team'
}

export default function DeleteAction({ onDelete, isDeleting, currentUserHasPermission, deleteAction, entity }: Props) {
  const toast = useToast()

  const handleDeleteClick = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      // Stop event propagation to prevent row click triggering the row handler instead of the delete action
      e.stopPropagation()
      try {
        await deleteAction()
        onDelete?.()

        toast({
          title: `${entity} deleted!`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        toast({
          title: `Failed to delete ${entity.toLowerCase()} team.`,
          description: getMessageFromRtkError(err),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    },
    [deleteAction, entity, onDelete, toast]
  )

  return (
    <IconButton
      variant="outline"
      aria-label={`Delete ${entity}`}
      border="none"
      icon={<DeleteIcon />}
      isDisabled={isDeleting || !currentUserHasPermission}
      isLoading={isDeleting}
      onClick={handleDeleteClick}
    />
  )
}
