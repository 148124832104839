import { Box, Card, CardBody, CardHeader, Heading, Stack, Stat, StatArrow, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react'

import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { formatPercentage } from '@/util/numbers'

interface Props {
  observed: number
  reported: number
}

export function DsbomReductionViz({ observed, reported }: Props) {
  const percent = observed / reported

  const pieData = [
    {
      name: 'Observed',
      value: reported > 0 ? observed : 1,
      fill: reported > 0 ? 'var(--chakra-colors-bad)' : 'var(--chakra-colors-circular-progress-track-color)'
    },
    {
      name: 'Reported',
      value: reported - observed,
      fill: observed === 0 ? 'var(--chakra-colors-good)' : 'var(--chakra-colors-circular-progress-track-color)'
    }
  ]

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Defects</Heading>
      </CardHeader>
      <CardBody paddingX={16} paddingY={4}>
        <Stack direction="row" spacing={1} align="center">
          <Box width={44} height={44} marginRight={16}>
            <ResponsiveContainer>
              <PieChart width={730} height={250}>
                <Pie
                  data={pieData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius="60%"
                  outerRadius="100%"
                  startAngle={450}
                  endAngle={90}
                  animationDuration={500}
                  stroke="none"
                />
                {reported > 0 && <Tooltip />}
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <Stack>
            <Stat border="none">
              <StatLabel fontWeight="semibold">Observed</StatLabel>
              <StatNumber fontWeight="semibold">{observed}</StatNumber>
              {!!reported && (
                <StatHelpText>
                  <StatArrow type="decrease" color={percent < 1 ? 'good' : 'gray'} />
                  {formatPercentage(reported - observed, { base: reported })} Reduction
                </StatHelpText>
              )}
            </Stat>
            <Stat border="none">
              <StatLabel>Reported</StatLabel>
              <StatNumber fontWeight="regular">{reported}</StatNumber>
            </Stat>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  )
}
