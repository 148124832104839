const baseColors = {
  gray: {
    25: '#F8FAFF',
    50: '#F4F6FF',
    100: '#EFF1F9',
    200: '#DEE3F2',
    300: '#CED6EC',
    400: '#BDC8E5',
    500: '#ADBADF',
    600: '#8A95B2',
    700: '#687086',
    800: '#454A59',
    825: '#343a47',
    850: '#2a2c33',
    900: '#23252D',
    950: '#121317'
  },
  blue: {
    50: '#EDEFFF',
    75: '#E9EBFF',
    100: '#DCE0FF',
    200: '#B9C0FF',
    300: '#97A1FF',
    400: '#7481FF',
    500: '#5162FF',
    600: '#414ECC',
    700: '#313B99',
    800: '#202766',
    900: '#101433'
  },
  purple: {
    25: '#F6F3FF',
    50: '#F6F3FF',
    100: '#E6DDFF',
    200: '#CDBAFF',
    300: '#B498FF',
    400: '#9B75FF',
    500: '#8253FF',
    600: '#6842CC',
    700: '#4E3299',
    800: '#342166',
    900: '#1A1133'
  },
  yellow: {
    50: '#FBFFE5',
    100: '#FEFFCC',
    200: '#FFFCAD',
    300: '#FBFD91',
    400: '#FFF963',
    500: '#FFF737',
    600: '#F6ED11',
    700: '#E4DB00',
    800: '#CBB700',
    900: '#B19F00'
  },
  orange: {
    50: '#FFF5E5',
    100: '#FFECCC',
    200: '#FFDFAD',
    300: '#FDD291',
    400: '#FFBB63',
    500: '#FFA037',
    600: '#F68511',
    700: '#E46F00',
    800: '#CB5D00',
    900: '#B15C00'
  },
  red: {
    50: '#FEF3F2',
    100: '#FED8D8',
    200: '#FDB1B1',
    300: '#FB8B8B',
    400: '#FA6464',
    500: '#F93D3D',
    600: '#C73131',
    700: '#952525',
    800: '#641818',
    900: '#320C0C'
  },
  green: {
    50: '#F2FEF3',
    100: '#D9FED8',
    200: '#BCFDB1',
    300: '#8BFB9D',
    400: '#64FA85',
    500: '#2AD259',
    600: '#30B956',
    700: '#259559',
    800: '#186436',
    900: '#0C3214'
  }
}

export const colors = {
  ...baseColors,
  // aliases for severity levels, error/success, etc.
  brand: baseColors.blue,
  severityLow: baseColors.yellow,
  warning: baseColors.orange,
  severityMedium: baseColors.orange,
  severityHigh: baseColors.red,
  error: baseColors.red,
  success: baseColors.green,
  good: baseColors.green,
  bad: baseColors.red
}
