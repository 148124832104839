import { Opaque } from '../../opaque'

/**
 * A run configuration is the information needed to create a run,
 *  an equivalent to Mayhemfile but without implicit information.
 */
export type CoreCodeRunConfig = Opaque<
  {
    version?: string
    workspaceSlug: string
    projectNameOrSlug: string // name when user-inputted, slug when read from Mayhemfile
    targetNameOrSlug: string // name when user-inputted, slug when read from Mayhemfile
    image?: string
    commands: CoreCodeRunCommand[]
    duration?: CodeRunConfigDuration
    tasks: CoreCodeTask[]
    advancedTriage: boolean
    uid?: number
    gid?: number
    testsuite: string[]
    isFirstRun: boolean
    rootSha?: string
  },
  'core:run-code-config'
>

export type CodeRunConfigDuration = number | undefined

export type CoreCodeTask = 'exploitability_factors' | 'behavior_testing' | 'regression_testing' | 'coverage_analysis'

export interface CoreCodeRunCommand {
  command: string
  environment: Record<string, string>
  network?: CoreCodeRunCommandNetwork
  // TODO(kostas): Document, keep in mind the information below
  //    - undefined means mayhem should decide
  honggfuzz: boolean | undefined
  libfuzzer: boolean | undefined
  mayhemFuzz: boolean | undefined
  mayhemSe: boolean | undefined
  sanitizer: boolean | undefined
  afl: boolean | undefined
  maxLength?: number
  cwd?: string
  filepath?: string
  memoryLimit?: number
  dictionary?: string
  timeout?: number
}

export interface CoreCodeRunCommandNetwork {
  url?: string
  timeout?: number
  client?: boolean
}

export const DefaultTasks: CoreCodeTask[] = [
  // format: no-inline
  'exploitability_factors',
  'behavior_testing',
  'regression_testing'
]

export const DefaultTestsuite = [
  // format: no-inline
  'https://$MAYHEM_DOMAIN/$MAYHEM_PROJECT/$MAYHEM_TARGET/testsuite.tar',
  'file://testsuite'
]
