import { quote } from 'shlex'

import { CoreApiRunConfig } from '../run-api-config/CoreApiRunConfig'

export const MapiUrlExample = 'https://demo-api.mayhem.security/api/v3'
export const MapiSpecUrlExample = MapiUrlExample + '/openapi.json'

export const runCommand = (config: CoreApiRunConfig | undefined) => {
  if (!config) {
    return ''
  }

  // workspace and project are previously validated, duration is always a number
  const additionalRunArgs = config.additionalRunArgs.map((arg) => quote(arg))
  return `mapi run "${config.workspaceSlug}/${config.projectSlug}/${config.targetSlug}" ${config.duration} ${quote(config.api.spec)} --url ${quote(
    config.api.url
  )} --interactive ${additionalRunArgs.join(' ')}`
}

export const toMacCli = (comment = true) => {
  const commentStr = comment ? '# Download and Install Mayhem CLI\n' : ''
  return `${commentStr}curl --fail -Lo mayhem.pkg ${window.location.protocol}//${window.location.host}/cli/Darwin/mayhem.pkg && \\
  sudo installer -pkg mayhem.pkg -target / && \\
  rm mayhem.pkg`
}

export const toLinuxCli = (comment = true) => {
  const commentStr = comment ? '# Download and Install Mayhem CLI\n' : ''
  return `${commentStr}curl -Lo mapi ${window.location.protocol}//${window.location.host}/cli/mapi/linux-musl/latest/mapi && \\
  chmod +x mapi && \\
  sudo mkdir -p /usr/local/bin/ && \\
  sudo install mapi /usr/local/bin/mapi`
}

export const toWindowsCli = (comment = true) => {
  const commentStr = comment ? '# Download and Install Mayhem CLI\n' : ''
  return `${commentStr}curl.exe -Lo mapi.exe ${window.location.protocol}//${window.location.host}/cli/mapi/windows-amd64/latest/mapi.exe`
}
