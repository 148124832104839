import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getWorkerStats: build.query<GetWorkerStatsApiResponse, GetWorkerStatsApiArg>({
        query: () => ({ url: `/api/v2/worker-stat` }),
        providesTags: ['internal']
      }),
      getWorkerUsageMetrics: build.query<GetWorkerUsageMetricsApiResponse, GetWorkerUsageMetricsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/worker/usage`,
          params: {
            sample_from_seconds: queryArg.sampleFromSeconds,
            sample_interval_seconds: queryArg.sampleIntervalSeconds,
            sample_to_seconds: queryArg.sampleToSeconds,
            worker_pool_id: queryArg.workerPoolId,
            workspace_slug: queryArg.workspaceSlug
          }
        }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetWorkerStatsApiResponse = /** status 200
    Returns list of high level worker stats
     */ WorkersStats
export type GetWorkerStatsApiArg = void
export type GetWorkerUsageMetricsApiResponse = /** status 200
    Samples worker usage metrics and returns a report.
    :param sample_from_seconds: Unix timestamp (in seconds) for the start of the report.
    :param sample_to_seconds: Unix timestamp (in seconds) for the end of the report.
    :param sample_interval_seconds: The duration in seconds between records of the
    report.
    :param workspace_slug: Optional, the workspace to report.
    :param worker_pool_id: Optional, the worker pool to report. `workspace_slug`
    overrides this.
    :return:
     */ WorkerUsageMetricsGet
export type GetWorkerUsageMetricsApiArg = {
  sampleFromSeconds: number
  sampleIntervalSeconds: number
  sampleToSeconds: number
  workerPoolId?: number | null
  workspaceSlug?: string | null
}
export type WorkerPoolStats = {
  worker_pool_id: number
  n_tasks_pending: number
  n_workers_allocated: number
  n_workers_active: number
}
export type WorkersStats = {
  n_cpu_cores_total: number
  n_cpu_cores_active: number
  n_memory_total: number
  n_memory_active: number
  n_total_workers: number
  n_active_workers: number
  n_per_worker_cpu: number
  n_per_worker_memory: number
  worker_pools: WorkerPoolStats[]
}
export type ApiError = {
  message?: string
}
export type WorkerUsageMetricsReport = {
  timestamp: string
  capacity_seconds: number
  up_seconds: number
  up_percent: number
  up_background_seconds: number
  up_background_percent: number
  up_ci_seconds: number
  up_ci_percent: number
  up_manual_seconds: number
  up_manual_percent: number
}
export type WorkerUsageMetricsGet = {
  results: WorkerUsageMetricsReport[]
}
