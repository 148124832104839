import { Box, HStack, Tab, TabList, TabPanels, Tabs, useToken } from '@chakra-ui/react'

import { formatNumber } from '@/util/numbers'
import { useUpdateQueryParams } from '@/util/location'
import { useQuery } from '@/hooks'

interface Props {
  totalCount: number
  apiCount: number
  codeCount: number
  children: React.ReactNode
}

export function RunTabs({ totalCount, apiCount, codeCount, children }: Props) {
  const tabs = Object.entries({ All: totalCount, Code: codeCount, API: apiCount })
  const [paddingX] = useToken('spacing', ['padding.X.card'])
  const query = useQuery()
  const updateQueryParams = useUpdateQueryParams()
  const selectedType = query.get('type') || tabs[0][0]
  const selectedIndex = tabs.findIndex((tab) => tab[0] === selectedType)

  return (
    <Tabs variant="enclosed" isLazy width="full" index={selectedIndex} onChange={(index) => updateQueryParams({ type: tabs[index][0] })}>
      <TabList paddingLeft={paddingX}>
        {tabs.map(([tabName, count]) => (
          <Tab key={tabName}>
            <HStack gap={1}>
              <Box fontWeight={600}>{tabName}</Box>
              <Box color="gray.500" fontWeight={500}>
                ({formatNumber(count)})
              </Box>
            </HStack>
          </Tab>
        ))}
      </TabList>
      <TabPanels>{children}</TabPanels>
    </Tabs>
  )
}
