import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'

import { Button, Heading, HStack } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import { getWorkspacePageTitle } from '@/titles'
import { Page } from '@/components/Page'
import WorkspaceProjectsList from '@/features/workspace-projects/WorkspaceProjectsList'

type Props = { workspaceSlug: string }

export function WorkspaceProjectsPage({ workspaceSlug }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Projects')
  }, [workspaceSlug])

  return (
    <Page
      header={
        <HStack gap={8}>
          <Heading>Projects</Heading>
          <WouterLink to={`/${workspaceSlug}/-/new-project`}>
            <Button leftIcon={<AddIcon />}>New Project</Button>
          </WouterLink>
        </HStack>
      }
    >
      <WorkspaceProjectsList workspaceSlug={workspaceSlug} title={null} />
    </Page>
  )
}
