import { FunctionComponent, SVGProps, memo, useRef } from 'react'
import { Link as WouterLink, useRoute } from 'wouter'
import { AnimatePresence } from 'framer-motion'
import { Box, Divider, Link as ChakraLink, Spacer, Stack, Text, IconButton, Icon, HStack } from '@chakra-ui/react'

import { WorkspaceWidget } from './WorkspaceWidget'

import { SettingsMenu } from './SettingsMenu'

import { ProjectMenu } from './ProjectMenu'

import { WorkspaceMenu } from './WorkspaceMenu'

import DownloadIcon from '@/images/nav-download.svg?react'
import DocsIcon from '@/images/nav-docs.svg?react'

import { NavButton } from '@/components/Shell/NavButton'
import { Sidebar } from '@/components/Shell/Sidebar'
import MayhemWordmark from '@/images/mayhem-wordmark.svg?react'
import UrlIcon from '@/images/url.svg?react'

import { usePermissions } from '@/hooks'
import { SIDEBAR_BASE_WIDTH, SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_SUBMENU_WIDTH } from '@/components/Shell/Shell'

export interface MenuItem {
  path: string
  onClick?: () => void // custom onClick handler, used for manually expanding the Settings submenu
  extraPathMatches?: string[] // additional path patterns to use for highlighting the active route
  name: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  key: string
  scrollTo?: string // used for scrolling to a particular section on a page with a scrolling nav setup
}

export interface MenuSection {
  name: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  routes: MenuItem[]
  isShown: boolean
}

export const SubnavSectionHeader = ({ name, color }: { name: string; color?: string }) => (
  <HStack>
    <Text color={color ? color : 'subnav-section-header'} paddingY={4} fontWeight={600}>
      {name}
    </Text>
  </HStack>
)

const InstallationButton = ({ isCollapsed = false }: { isCollapsed?: boolean }) => {
  return (
    <WouterLink to="/-/installation">
      <NavButton fontSize="md" label="Install CLI" icon={DownloadIcon} isActive={useRoute('/-/installation')[0]} isCollapsed={isCollapsed} />
    </WouterLink>
  )
}

const WorkspaceSidebarInner = ({ workspaceSlug, projectSlug, submenu }: WorkspaceSidebarProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { isAdmin, allowWrite, userSlug } = usePermissions(workspaceSlug)

  const showWorkspaceSettings = allowWrite || userSlug === workspaceSlug
  const showAdminSettings = !!isAdmin

  const isCollapsed = !!submenu

  return (
    <Stack direction="row" spacing={0} height="full">
      <Sidebar key="workspace-sidebar" ref={containerRef} width={isCollapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_BASE_WIDTH}>
        <Stack
          flex="1"
          overflowX="auto"
          spacing={4}
          paddingY={2}
          paddingX={isCollapsed ? 2 : 4}
          background={isCollapsed ? 'collapsed-menu-bg' : undefined}
          borderWidth={isCollapsed ? '1px' : undefined}
          borderColor={isCollapsed ? 'collapsed-menu-border' : undefined}
        >
          <WouterLink to={`/${workspaceSlug}`}>
            <IconButton
              aria-label="Home"
              variant="sidenav-link"
              height="auto"
              width="full"
              justifyContent={!isCollapsed ? 'start' : 'center'}
              marginTop={{ base: 16, lg: 0 }} // allows space for drawer close button
              icon={
                !isCollapsed ? (
                  <Icon as={MayhemWordmark} boxSize={44} height={16} fill="chakra-body-text" paddingX={2} />
                ) : (
                  <Icon as={UrlIcon} boxSize={8} height={16} fill="chakra-body-text" />
                )
              }
            />
          </WouterLink>
          <Box>
            <WorkspaceMenu workspaceSlug={workspaceSlug} isCollapsed={isCollapsed} submenu={submenu} />
          </Box>
          <Spacer />
          <Stack spacing={1} alignItems={isCollapsed ? 'center' : undefined}>
            <InstallationButton isCollapsed={isCollapsed} />
            <ChakraLink href="https://docs.mayhem.security" isExternal>
              <NavButton fontSize="md" label="Documentation" icon={DocsIcon} isCollapsed={isCollapsed} />
            </ChakraLink>
          </Stack>
          {isCollapsed && <Divider borderColor="collapsed-menu-divider" />}
          <WorkspaceWidget workspaceSlug={workspaceSlug} isCollapsed={isCollapsed} />
        </Stack>
      </Sidebar>
      {submenu === 'project' && (
        <AnimatePresence mode="wait">
          <Sidebar width={SIDEBAR_SUBMENU_WIDTH}>
            <ProjectMenu workspaceSlug={workspaceSlug} projectSlug={projectSlug as string} />
          </Sidebar>
        </AnimatePresence>
      )}
      {submenu === 'settings' && (
        <AnimatePresence mode="wait">
          <Sidebar width={SIDEBAR_SUBMENU_WIDTH}>
            <SettingsMenu workspaceSlug={workspaceSlug} showWorkspaceSettings={showWorkspaceSettings} showAdminSettings={showAdminSettings} />
          </Sidebar>
        </AnimatePresence>
      )}
    </Stack>
  )
}

export const WorkspaceSidebar = memo(WorkspaceSidebarInner)

export interface WorkspaceSidebarProps {
  workspaceSlug: string
  projectSlug?: string
  submenu?: 'project' | 'settings'
}
