import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
  useToken
} from '@chakra-ui/react'

import { UserPermissionsTable } from './UserPermissionsTable'
import { TeamPermissionsTable } from './TeamPermissionsTable'
import { UserPermissionsModal } from './UserPermissionsModal'
import { TeamPermissionsModal } from './TeamPermissionsModal'

import { useQuery } from '@/hooks'
import { useUpdateQueryParams } from '@/util/location'

interface Props {
  workspaceSlug: string
  projectSlug: string
  canManageTeams: boolean
  isWorkspaceLoading: boolean
  isAtLeastWritePermission: boolean
}

export default function PermissionsList({ workspaceSlug, projectSlug, canManageTeams, isWorkspaceLoading, isAtLeastWritePermission }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const tabs = ['User', 'Team']
  const query = useQuery()
  const updateQueryParams = useUpdateQueryParams()
  const [paddingX] = useToken('spacing', ['padding.X.card'])

  const selectedType = query.get('type') || tabs[0]
  const selectedIndex = tabs.indexOf(selectedType)
  const selectedTabIndex = selectedIndex >= 0 ? selectedIndex : 0

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Contributors</Heading>
        {isAtLeastWritePermission && <Button onClick={onOpen}>Add {tabs[selectedTabIndex]}</Button>}
      </CardHeader>
      <CardBody py={4}>
        <Tabs variant="enclosed" isLazy width="full" tabIndex={selectedTabIndex} onChange={(index) => updateQueryParams({ type: tabs[index] })}>
          <TabList paddingLeft={paddingX}>
            <Tab fontWeight={600}>Users</Tab>
            <Tab isDisabled={isWorkspaceLoading || !canManageTeams} fontWeight={600}>
              <Tooltip
                isDisabled={canManageTeams}
                label={`The project "${projectSlug}" is a part of the ${workspaceSlug} personal account. Teams can only be created and added to projects belonging to organizations.`}
              >
                Teams
              </Tooltip>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingInline={0}>
              <UserPermissionsTable workspaceSlug={workspaceSlug} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
            <TabPanel paddingInline={0}>
              <TeamPermissionsTable workspaceSlug={workspaceSlug} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
      <UserPermissionsModal workspaceSlug={workspaceSlug} projectSlug={projectSlug} open={selectedType === 'User' && isOpen} onClose={onClose} />
      <TeamPermissionsModal workspaceSlug={workspaceSlug} projectSlug={projectSlug} open={selectedType === 'Team' && isOpen} onClose={onClose} />
    </Card>
  )
}
