import { HStack, Stack, Text, Badge } from '@chakra-ui/react'

interface Props {
  latencyP50Millis?: number
  latencyP90Millis?: number
  latencyP99Millis?: number
}

export default function EndpointLatencies({ latencyP50Millis, latencyP90Millis, latencyP99Millis }: Props) {
  if ([latencyP50Millis, latencyP90Millis, latencyP99Millis].every((latency) => latency === undefined)) {
    return '-'
  }

  return (
    <Stack gap={2}>
      {Object.entries({ p50: latencyP50Millis, p90: latencyP90Millis, p99: latencyP99Millis }).map(([label, value]) =>
        value !== undefined ? (
          <HStack key={label}>
            <Text fontSize={12}>{label}</Text> <Badge variant="null">{value}ms</Badge>
          </HStack>
        ) : null
      )}
    </Stack>
  )
}
