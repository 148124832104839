import { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts'

export interface ChartProps {
  records: Record<string, unknown>[]
  children?: ReactNode
}

export function Chart({ records, children }: ChartProps) {
  return (
    <Box as="span" color="chartAxis">
      <ResponsiveContainer width="100%">
        <AreaChart data={records}>
          <CartesianGrid vertical={false} stroke="var(--chakra-colors-chartAxis)" />
          {children}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  )
}
