import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tabs: ComponentStyleConfig = {
  defaultProps: {
    variant: 'solid-rounded'
  },
  variants: {
    enclosed: {
      tab: {
        _selected: {
          _dark: {
            borderBottomColor: 'chakra-body-bg'
          }
        }
      }
    }
  }
}
