import { Avatar, GridProps, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import { memo } from 'react'

import { useGetAccountQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { WorkspaceDropdown } from './WorkspaceDropdown'

const WorkspaceWidgetInner = ({ workspaceSlug, isCollapsed }: WorkspaceWidgetProps) => {
  const { data: profile } = useWhoamiQuery()
  const { isLoading: isLoadingActiveWorkspace, data: activeWorkspace } = useGetAccountQuery(workspaceSlug ? { owner: workspaceSlug } : skipToken)

  if (isLoadingActiveWorkspace) {
    return (
      <HStack spacing={2}>
        <SkeletonCircle height={10} width={10} />
        {!isCollapsed && <Skeleton height={5} width={36} />}
      </HStack>
    )
  }

  const icon =
    profile !== undefined && workspaceSlug !== undefined && profile.slug == workspaceSlug ? (
      <Avatar
        name={activeWorkspace?.orgname}
        src={profile.avatar ?? undefined}
        height="30px"
        width="30px"
        lineHeight="2rem"
        bg="gray"
        color="white"
      />
    ) : (
      <Avatar name={activeWorkspace?.orgname} height="30px" width="30px" lineHeight="2rem" bg="gray" color="white" />
    )

  return (
    <WorkspaceDropdown
      workspaceSlug={workspaceSlug}
      icon={icon}
      menuButtonProps={{ width: !isCollapsed ? 'full' : undefined, padding: !isCollapsed ? 2 : 0 }}
      isCollapsed={isCollapsed}
    />
  )
}

export const WorkspaceWidget = memo(WorkspaceWidgetInner)

export interface WorkspaceWidgetProps {
  container?: GridProps
  workspaceSlug?: string
  isCollapsed?: boolean
}
